<template lang="html">
  <HelpBox>
    <IxRes>frs.navigation.tags.hints.pleaseSelectSomething</IxRes>
  </HelpBox>
</template>

<script>
import HelpBox from '@components/help/HelpBox'

export default {
  components: {
    HelpBox
  }
}
</script>

<style lang="scss" scoped>
</style>
